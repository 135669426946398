<template>
  <b-form-group
    label="Sahiplik Durumu"
    label-for="ownership"
  >
    <validation-provider
      #default="{ errors }"
      name="Sahiplik Durumu"
      rules="required"
    >
      <v-select
        id="ownership"
        v-model="dataItem.ownership"
        placeholder="Sahiplik Durumu"
        label="title"
        :options="dataList"
        :reduce="item => item.id"
        @input="resetData"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'OwnerShip',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      required,
      dataList: [
        {
          id: '1',
          title: 'Özmal',
        },
        {
          id: '0',
          title: 'Kiralama',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalVehiclePark/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    resetData() {
      if (this.dataItem.ownership === '1') {
        this.dataItem.contract = null
      }
    },
  },
}
</script>

<template>
  <b-form-group
    label="Marka"
    label-for="id_com_swap_brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="id_com_swap_brand"
        v-model="dataItem.id_com_swap_brand"
        label="title"
        :reduce="item => item.id"
        :options="brands"
        placeholder="Marka"
        @input="getModels"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalVehiclePark/dataItem']
    },
    brands() {
      return this.$store.getters['swapbrands/getSwapbrands']
    },
  },
  created() {
    localize('tr')
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('swapbrands/swapbrandsList', {
        select: ['com_swap_brand.id AS id', 'com_swap_brand.name AS title'],
      })
    },
    getModels() {
      if (this.dataItem.id_com_swap_brand) {
        this.dataItem.id_com_swap_model = null
        this.$store.dispatch('swapmodels/swapmodelsList', {
          select: ['com_swap_model.id AS id', 'com_swap_model.title AS title'],
          where: {
            id_com_swap_brand: this.dataItem.id_com_swap_brand,
          },
        })
      }
    },
  },
}
</script>

<style scoped>
</style>

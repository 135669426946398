<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Araç Ekle</span>
        </template>
        <item-form :is-update="true" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ItemForm from '@/views/Rental/VehiclePark/ItemForm.vue'

export default {
  name: 'Edit',
  components: {
    BTabs,
    BTab,
    Customer,
    ItemForm,
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('rentalVehiclePark/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_com_customer) {
            this.getCustomer(res.id_com_customer)
            this.getModel(res.id_com_swap_brand)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getModel(id) {
      this.$store.dispatch('swapmodels/swapmodelsList', {
        select: ['com_swap_model.id AS id', 'com_swap_model.title AS title'],
        where: {
          id_com_swap_brand: id,
        },
      })
    },
  },
}
</script>

<template>
  <b-form-group
    label="Model"
    label-for="id_com_swap_model"
  >
    <validation-provider
      #default="{ errors }"
      name="Model"
      rules="required"
    >
      <v-select
        id="id_com_swap_model"
        v-model="dataItem.id_com_swap_model"
        label="title"
        :reduce="item => item.id"
        :options="models"
        placeholder="Model"
        :disabled="!dataItem.id_com_swap_brand"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalVehiclePark/dataItem']
    },
    models() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

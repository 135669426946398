<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <owner-ship />
        </b-col>
        <template v-if="dataItem.ownership">
          <b-col
            v-if="dataItem.ownership === '0'"
            cols="12"
          >
            <contract-date />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <brands />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <models />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <model-year />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <quantity />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <contract-statuses :is-update="isUpdate" />
          </b-col>
          <b-col
            cols="12"
          >
            <notes />
          </b-col>
          <b-col cols="12">
            <div
              class="d-flex justify-content-between"
            >
              <b-button
                v-if="!isUpdate"
                variant="danger"
                :disabled="submitStatus"
                @click="submitForm(1)"
              >
                Kaydet ve Yeni Araç Ekle
              </b-button>
              <b-button
                variant="danger"
                :disabled="submitStatus"
                @click="submitForm"
              >
                Kaydet
              </b-button>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import OwnerShip from '@/views/Rental/VehiclePark/elements/OwnerShip.vue'
import Brands from '@/views/Rental/VehiclePark/elements/Brands.vue'
import Models from '@/views/Rental/VehiclePark/elements/Models.vue'
import ModelYear from '@/views/Rental/VehiclePark/elements/ModelYear.vue'
import Quantity from '@/views/Rental/VehiclePark/elements/Quantity.vue'
import ContractDate from '@/views/Rental/VehiclePark/elements/ContractDate.vue'
import ContractStatuses from '@/views/Rental/VehiclePark/elements/ContractStatuses.vue'
import Notes from '@/views/Rental/VehiclePark/elements/Notes.vue'

export default {
  name: 'ItemForm',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    OwnerShip,
    Brands,
    Models,
    ModelYear,
    Quantity,
    ContractDate,
    ContractStatuses,
    Notes,
    ValidationObserver,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      submitStatus: false,
      addNew: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalVehiclePark/dataItem']
    },
    saveStatus() {
      return this.$store.getters['rentalVehiclePark/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      if (!this.isUpdate) {
        if (this.addNew) {
          this.$store.commit('rentalVehiclePark/RESET_DATA_ITEM')
          this.$router.push(`/rental/vehicle-park/add/${this.$route.params.id}`)
        } else {
          this.$router.push('/rental/vehicle-park')
        }
      }
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm(addNew) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.isUpdate) {
            this.dataItem.id_com_customer = this.$route.params.id
          }
          if (addNew) {
            this.addNew = true
          }
          this.$store.dispatch('rentalVehiclePark/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
